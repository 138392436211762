import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <circle
        cx="80.0881"
        cy="80.2446"
        r="70"
        stroke="white"
        strokeOpacity="0.15"
        strokeDasharray="4 10"
      />
      <circle opacity="0.05" cx="80.3123" cy="80.4688" r="45" fill="url(#paint0_linear_15_7391)" />
      <g opacity="0.4">
        <path
          d="M75.0293 82.6989H74.5293V82.1989V77.8011V77.3011H75.0293H82.564L83.3788 74.5526L74.7494 68.7221L74.5293 68.5734V68.3078V63V62.0557L75.3103 62.5864L89.281 72.0798L89.5853 72.2865L89.4784 72.6386L87.238 80.0152L89.4784 87.3917L89.5856 87.7447L89.28 87.9513L75.28 97.4142L74.5 97.9415V97V91.6922V91.4261L74.7207 91.2775L83.3762 85.4485L82.5377 82.6989H75.0293Z"
          stroke="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_15_7391"
        x1="125.473"
        y1="125.144"
        x2="35.3491"
        y2="35.8114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
