import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { useWindowEvent } from 'src/js/utils/hooks'

import 'src/components/carousel/Carousel/scss/styles.scss'

const Arrow = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M29 22L23 28L29 34" stroke="white" strokeWidth="2" strokeLinecap="square" />
    <circle opacity="0.3" cx="28" cy="28" r="27.5" transform="rotate(-180 28 28)" stroke="white" />
  </svg>
)

const Carousel = ({ items, disablePeriod, onChange }) => {
  const wrapperElementRef = useRef()

  const [activeIndex, setActiveIndex] = useState(0)
  const maxIndex = items.length - 1
  const [largestItemHeight, setLargestItemHeight] = useState(0)
  const [largestItemWidth, setLargestItemWidth] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const calculateDimensions = () => {
    const wrapperElement = wrapperElementRef.current

    if (wrapperElement) {
      const itemElements = wrapperElement.querySelectorAll('.x-carousel__item-wrapper')

      let height = 0
      let width = 0
      for (let i = 0; i < itemElements.length; i++) {
        const item = itemElements[i]

        height = Math.max(height, item.offsetHeight)
        width = Math.max(width, item.offsetWidth)
      }

      setLargestItemHeight(height)
      setLargestItemWidth(width)
    }
  }

  useEffect(() => {
    calculateDimensions()
  }, [])

  useWindowEvent('resize', calculateDimensions)

  useEffect(() => {
    if (!onChange) return

    onChange(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    if (!disablePeriod) return

    setDisabled(true)

    const timeout = setTimeout(() => {
      setDisabled(false)
    }, disablePeriod)

    return () => clearTimeout(timeout)
  }, [activeIndex])

  const previousClicked = () => {
    if (disabled) return

    const newIndex = activeIndex - 1
    setActiveIndex(newIndex >= 0 ? newIndex : maxIndex)
  }

  const nextClicked = () => {
    if (disabled) return

    const newIndex = activeIndex + 1
    setActiveIndex(newIndex <= maxIndex ? newIndex : 0)
  }

  const leftArrow = (
    <Arrow
      className={classNames('x-carousel__arrow x-carousel__arrow--left', {
        'x-carousel__arrow--disabled': disabled,
      })}
      onClick={previousClicked}
    />
  )

  const rightArrow = (
    <Arrow
      className={classNames('x-carousel__arrow x-carousel__arrow--right', {
        'x-carousel__arrow--disabled': disabled,
      })}
      onClick={nextClicked}
    />
  )

  return (
    <div className="x-carousel" ref={wrapperElementRef}>
      {leftArrow}

      <div
        className="x-carousel__items"
        style={{ height: `${largestItemHeight}px`, width: `${largestItemWidth}px` }}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames('x-carousel__item-wrapper', {
                'x-carousel__item-wrapper--active': index === activeIndex,
              })}
            >
              {item}
            </div>
          )
        })}
      </div>

      {rightArrow}

      <div className="x-carousel__mobile-arrows">
        {leftArrow}
        {rightArrow}
      </div>
    </div>
  )
}

export default Carousel
