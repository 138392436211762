import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { usePrevious } from 'src/js/utils/hooks'

import Line from './Line'
import UpDownArrowsActive from './symbols/UpDownArrowsActive'
import UpDownArrowsInactive from './symbols/UpDownArrowsInactive'
import Number3Active from './symbols/Number3Active'
import Number3Inactive from './symbols/Number3Inactive'
import LetterEActive from './symbols/LetterEActive'
import LetterEInactive from './symbols/LetterEInactive'
import ShieldActive from './symbols/ShieldActive'
import ShieldInactive from './symbols/ShieldInactive'
import InfinityActive from './symbols/InfinityActive'
import InfinityInactive from './symbols/InfinityInactive'
import ScarabActive from './symbols/ScarabActive'
import ScarabInactive from './symbols/ScarabInactive'
import PyramidActive from './symbols/PyramidActive'
import PyramidInactive from './symbols/PyramidInactive'
import ChainActive from './symbols/ChainActive'
import ChainInactive from './symbols/ChainInactive'

import './styles.scss'

const transitionPeriod = 400 // how long it takes to flip, in ms

const itemsData = [
  {
    active: <UpDownArrowsActive />,
    inactive: <UpDownArrowsInactive />,
  },
  {
    active: <Number3Active />,
    inactive: <Number3Inactive />,
  },
  {
    active: <LetterEActive />,
    inactive: <LetterEInactive />,
  },
  {
    active: <ShieldActive />,
    inactive: <ShieldInactive />,
  },
  {
    active: <InfinityActive />,
    inactive: <InfinityInactive />,
  },
  {
    active: <ScarabActive />,
    inactive: <ScarabInactive />,
  },
  {
    active: <PyramidActive />,
    inactive: <PyramidInactive />,
  },
  {
    active: <ChainActive />,
    inactive: <ChainInactive />,
  },
]

const maxIndex = itemsData.length - 1

const Item = ({ index, isActive }) => {
  const itemData = itemsData[index]

  return (
    <div
      className={classNames('x-page-brand__gallery__item', {
        'x-page-brand__gallery__item--active': isActive,
      })}
    >
      {itemData.active}
      {itemData.inactive}
    </div>
  )
}

const Gallery = ({ activeIndex }) => {
  const [renderedItemIndices, setRenderedItemIndices] = useState()
  const [goingBack, setGoingBack] = useState(null)
  const [goingForward, setGoingForward] = useState(null)
  const [transitioning, setTransitioning] = useState(false)

  const prevActiveIndex = usePrevious(activeIndex)

  useEffect(() => {
    const prevIndex = activeIndex === 0 ? maxIndex : activeIndex - 1
    const beforePrevIndex = prevIndex === 0 ? maxIndex : prevIndex - 1
    const nextIndex = activeIndex === maxIndex ? 0 : activeIndex + 1
    const afterNextIndex = nextIndex === maxIndex ? 0 : nextIndex + 1

    const timeout = setTimeout(() => {
      setRenderedItemIndices([beforePrevIndex, prevIndex, activeIndex, nextIndex, afterNextIndex])
      setGoingBack(null)
      setGoingForward(null)
    }, transitionPeriod + 100)

    return () => clearTimeout(timeout)
  }, [activeIndex])

  useEffect(() => {
    if (prevActiveIndex === undefined) return

    if (prevActiveIndex === 0 && activeIndex === maxIndex) {
      return setGoingBack(true)
    } else if (prevActiveIndex === maxIndex && activeIndex === 0) {
      return setGoingForward(true)
    } else if (activeIndex > prevActiveIndex) {
      return setGoingForward(true)
    } else if (activeIndex < prevActiveIndex) {
      return setGoingBack(true)
    }
  }, [activeIndex])

  useEffect(() => {
    if (goingBack || goingForward) {
      setTransitioning(true)

      const timeout = setTimeout(() => setTransitioning(false), transitionPeriod / 2)

      return () => clearTimeout(timeout)
    }
  }, [goingBack, goingForward])

  if (!renderedItemIndices) return null

  return (
    <div className="x-page-brand__gallery__wrapper">
      <div
        className={classNames('x-page-brand__gallery__line-container', {
          'x-page-brand__gallery__line-container--fade': transitioning,
        })}
      >
        <Line />
      </div>

      <div
        className={classNames('x-page-brand__gallery', {
          'x-page-brand__gallery--going-back': goingBack,
          'x-page-brand__gallery--going-forward': goingForward,
        })}
      >
        <Item index={renderedItemIndices[0]} />
        <Item index={renderedItemIndices[1]} isActive={goingBack} />
        <Item index={renderedItemIndices[2]} isActive={!goingForward && !goingBack} />
        <Item index={renderedItemIndices[3]} isActive={goingForward} />
        <Item index={renderedItemIndices[4]} />
      </div>
    </div>
  )
}

export default Gallery
