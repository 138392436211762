import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        opacity="0.75"
        d="M80.0881 150.745C118.748 150.745 150.088 119.405 150.088 80.7446C150.088 42.0847 118.748 10.7446 80.0881 10.7446C41.4282 10.7446 10.0881 42.0847 10.0881 80.7446C10.0881 119.405 41.4282 150.745 80.0881 150.745Z"
        stroke="white"
        strokeOpacity="0.15"
        strokeDasharray="4 10"
      />
      <path
        opacity="0.1"
        d="M80.3081 125.973C105.161 125.973 125.308 105.825 125.308 80.9727C125.308 56.1198 105.161 35.9727 80.3081 35.9727C55.4553 35.9727 35.3081 56.1198 35.3081 80.9727C35.3081 105.825 55.4553 125.973 80.3081 125.973Z"
        fill="url(#paint0_linear_374_6221)"
      />
      <g opacity="0.2">
        <path
          opacity="0.6"
          d="M80.0344 63.6311L67.5847 70.2119L67.1175 68.9216L72.363 64.9182L72.7604 64.6148L72.4571 64.2174L70.1516 61.1966L69.8482 60.7992L69.4508 61.1025L61.6966 67.0207L61.4049 67.2433L61.5299 67.5884L64.0053 74.4255L65.9626 80.057L64.0159 84.6689L64.0053 84.694L63.9975 84.7201L61.6057 92.7147L61.5119 93.028L61.7615 93.2395L70.7336 100.841L71.1151 101.165L71.4383 100.783L73.8948 97.884L74.2181 97.5025L73.8366 97.1792L67.0105 91.3956L67.6179 89.3656L79.983 97.9465L80.268 98.1443L80.5531 97.9465L92.9069 89.3734L93.5119 91.3956L86.6859 97.1792L86.3044 97.5025L86.6276 97.884L89.0841 100.783L89.4073 101.165L89.7888 100.841L98.761 93.2395L99.0039 93.0336L98.9201 92.7264L97.1014 86.0573L97.0926 86.0251L97.0797 85.9944L94.5735 80.057L96.8571 73.4867L96.8592 73.4807L98.9926 67.5884L99.1175 67.2433L98.8258 67.0207L91.0717 61.1025L90.6742 60.7992L90.3709 61.1966L88.0654 64.2174L87.762 64.6148L88.1595 64.9182L93.4049 68.9216L92.94 70.2059L80.5017 63.6311L80.268 63.5076L80.0344 63.6311ZM80.022 75.8954L80.2692 76.0358L80.5163 75.8952L86.7356 72.3556L91.3253 74.7817L90.297 77.7405H70.2391L69.2108 74.7817L73.795 72.3585L80.022 75.8954ZM80.2669 71.4812L77.6798 69.6904L80.268 67.9716L82.8532 69.6887L80.2669 71.4812ZM82.668 82.5405H90.4117L91.3003 84.6458L82.668 90.6362V82.5405ZM77.868 82.5405V90.6362L69.2358 84.6458L70.1244 82.5405H77.868Z"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_374_6221"
        x1="125.469"
        y1="125.648"
        x2="35.3449"
        y2="36.3153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
