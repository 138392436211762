import JSZip from 'jszip'

const createDownloadZip = ({ files, name }) => {
  if (!files.length) return

  const zip = new JSZip()

  const fetchPromises = files.map((item) => {
    const folder = zip.folder(item.label)

    // Fetch each file and push the promise to the array.
    const filePromises = item.files.map((filePath) => {
      const fetchPromise = fetch(filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = filePath.substring(filePath.lastIndexOf('/') + 1)
          folder.file(fileName, blob)
        })

      return fetchPromise
    })

    return Promise.all(filePromises)
  })

  // Wait for promises to resolve, then generate and download Zip file.
  Promise.all(fetchPromises).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const url = window.URL.createObjectURL(content)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
    })
  })
}

export default createDownloadZip
