import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        opacity="0.75"
        d="M80.0881 150.745C118.748 150.745 150.088 119.405 150.088 80.7446C150.088 42.0847 118.748 10.7446 80.0881 10.7446C41.4282 10.7446 10.0881 42.0847 10.0881 80.7446C10.0881 119.405 41.4282 150.745 80.0881 150.745Z"
        stroke="white"
        strokeOpacity="0.15"
        strokeDasharray="4 10"
      />
      <path
        opacity="0.1"
        d="M80.3081 125.973C105.161 125.973 125.308 105.825 125.308 80.9727C125.308 56.1198 105.161 35.9727 80.3081 35.9727C55.4553 35.9727 35.3081 56.1198 35.3081 80.9727C35.3081 105.825 55.4553 125.973 80.3081 125.973Z"
        fill="url(#paint0_linear_374_6236)"
      />
      <g opacity="0.2">
        <path
          d="M94.4326 89.5L97 83.4885L80 69.5L63 83.4885L65.5674 89.5H94.4326Z"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_374_6236"
        x1="125.469"
        y1="125.648"
        x2="35.3449"
        y2="36.3153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
