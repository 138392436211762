import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="80.0881"
      cy="80.2446"
      r="70"
      stroke="white"
      strokeOpacity="0.15"
      strokeDasharray="4 10"
    />
    <circle opacity="0.05" cx="80.3081" cy="80.4727" r="45" fill="url(#paint0_linear_15_7388)" />
    <path
      d="M95.0496 76.8115L66.4624 76.2844L65.0917 71.5967L80.8701 61.7184L96.6292 72.1782L95.0496 76.8115Z"
      fill="white"
    />
    <path
      d="M65.9923 82.7575L94.5795 83.2846L95.9446 88.277L80.1734 98.7777L64.4072 87.6955L65.9923 82.7575Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_15_7388"
        x1="125.469"
        y1="125.148"
        x2="35.3449"
        y2="35.8153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
