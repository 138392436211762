import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        opacity="0.75"
        d="M80.0881 150.745C118.748 150.745 150.088 119.405 150.088 80.7446C150.088 42.0847 118.748 10.7446 80.0881 10.7446C41.4282 10.7446 10.0881 42.0847 10.0881 80.7446C10.0881 119.405 41.4282 150.745 80.0881 150.745Z"
        stroke="white"
        strokeOpacity="0.15"
        strokeDasharray="4 10"
      />
      <path
        opacity="0.1"
        d="M80.3081 125.973C105.161 125.973 125.308 105.825 125.308 80.9727C125.308 56.1198 105.161 35.9727 80.3081 35.9727C55.4553 35.9727 35.3081 56.1198 35.3081 80.9727C35.3081 105.825 55.4553 125.973 80.3081 125.973Z"
        fill="url(#paint0_linear_374_6206)"
      />
      <g opacity="0.2">
        <path
          d="M80.2729 61.384L79.9967 61.201L79.7206 61.384L63.0353 72.4426L62.714 72.6555L62.8381 73.0204L65.2107 79.9974L62.8385 86.9799L62.7146 87.3447L63.0357 87.5575L79.7206 98.6158L79.9967 98.7989L80.2729 98.6159L96.9644 87.5579L97.2856 87.3451L97.1616 86.9802L94.7893 79.9974L97.162 73.02L97.2861 72.6551L96.9648 72.4422L80.2729 61.384ZM79.9974 92.201L69.2854 85.1014L70.2542 82.2499H89.7458L90.7144 85.101L79.9974 92.201ZM90.7139 74.8985L90.0843 76.7499H69.9157L69.286 74.8981L79.9974 67.7989L90.7139 74.8985Z"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_374_6206"
        x1="125.469"
        y1="125.648"
        x2="35.3449"
        y2="36.3153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
