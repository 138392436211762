import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        opacity="0.75"
        d="M80.0881 150.745C118.748 150.745 150.088 119.405 150.088 80.7446C150.088 42.0847 118.748 10.7446 80.0881 10.7446C41.4282 10.7446 10.0881 42.0847 10.0881 80.7446C10.0881 119.405 41.4282 150.745 80.0881 150.745Z"
        stroke="white"
        strokeOpacity="0.15"
        strokeDasharray="4 10"
      />
      <path
        opacity="0.1"
        d="M80.3081 125.973C105.161 125.973 125.308 105.825 125.308 80.9727C125.308 56.1198 105.161 35.9727 80.3081 35.9727C55.4553 35.9727 35.3081 56.1198 35.3081 80.9727C35.3081 105.825 55.4553 125.973 80.3081 125.973Z"
        fill="url(#paint0_linear_374_6273)"
      />
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.1849 62.4966L92.3423 62.5486L98.2788 76.1325L91.8049 85.5038L87.7369 85.4898L89.2566 89.1252L83.0597 98.5033L68.419 98.4612L62.7436 84.8846L68.9401 75.4963L72.3122 75.5084L70.7216 71.8688L77.1849 62.4966ZM74.0631 79.5148L71.0876 79.5041L67.2564 85.3088L71.0855 94.4688L80.9125 94.4971L84.7434 88.6998L83.3953 85.4749L76.6577 85.4517L74.0631 79.5148ZM86.0624 81.484L83.5814 75.5489L76.6844 75.5241L75.2784 72.307L79.2804 66.5038L89.7211 66.5397L93.7212 75.6925L89.7115 81.4966L86.0624 81.484ZM81.7207 81.4691L79.2788 81.4607L78.4353 79.5305L80.9141 79.5394L81.7207 81.4691Z"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_374_6273"
        x1="125.469"
        y1="125.648"
        x2="35.3449"
        y2="36.3153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
