// Dependencies.
import classNames from 'classnames'
import React, { useState } from 'react'
// Utils.
import createDownloadZip from 'src/components/pages/brand/DownloadLogoSet/createDownloadZip'

// Styles & images.
import 'src/components/pages/brand/DownloadLogoSet/scss/Button.scss'

function ArrowIcon() {
  return (
    <svg width={10} height={6} viewBox="0 0 10 6">
      <path fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth={1.5} d="M9 1L5 5 1 1" />
    </svg>
  )
}

function Dropdown({ items = [], isOpen = false, onZipClick }) {
  return (
    <div className={classNames('x__menu x__menu', { 'x__menu--open': isOpen })}>
      <ul>
        {items.map(({ files, label }, index) => (
          <li key={index} className="x__menu__item">
            <span>
              <img src="/brand/img/file-icon.png" />
              {label}
            </span>
            <img src="/brand/img/download-icon.svg" />
            <ul>
              {files.map((file) => (
                <li key={file}>
                  <a href={file} download>
                    {file.split('.').pop()}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
        <li className="x__menu__item x__menu__item-zip">
          <button onClick={onZipClick} type="button">
            Download complete set – ZIP file
          </button>
        </li>
      </ul>
    </div>
  )
}

const downloadLogoItems = [
  {
    label: 'Exodus Logo dark',
    files: [
      '/brand/dl/images/Exodus_logo_dark.png',
      '/brand/dl/images/Exodus_logo_dark.svg',
      '/brand/dl/images/Exodus_logo_dark.ai',
    ],
  },
  {
    label: 'Exodus Logo white',
    files: [
      '/brand/dl/images/Exodus_logo_white.png',
      '/brand/dl/images/Exodus_logo_white.svg',
      '/brand/dl/images/Exodus_logo_white.ai',
    ],
  },
  {
    label: 'Exodus Symbol',
    files: [
      '/brand/dl/images/Exodus_symbol.png',
      '/brand/dl/images/Exodus_symbol.svg',
      '/brand/dl/images/Exodus_symbol.ai',
    ],
  },
]

// Main component.
function Button({ copy, items }) {
  const [toggleOpen, setToggleOpen] = useState(false)

  const handleZipDownload = () => {
    createDownloadZip({ files: downloadLogoItems, name: 'Exodus_Logos_Set.zip' })
  }

  return (
    <div
      className={classNames('x__brand-page__download-button', {
        'x__brand-page__download-button--open': toggleOpen,
      })}
      onClick={() => setToggleOpen((previous) => !previous)}
    >
      <div className="x__brand-page__download-button__toggle">
        <span>{copy}</span>
        <div className="x__toggle__picker">
          <ArrowIcon />
        </div>
      </div>

      <Dropdown items={items} isOpen={toggleOpen} onZipClick={handleZipDownload} />
    </div>
  )
}

export default Button
