import React from 'react'

const Icon = () => (
  <svg
    width="160"
    height="161"
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.0881 150.745C118.748 150.745 150.088 119.405 150.088 80.7446C150.088 42.0847 118.748 10.7446 80.0881 10.7446C41.4282 10.7446 10.0881 42.0847 10.0881 80.7446C10.0881 119.405 41.4282 150.745 80.0881 150.745Z"
      stroke="white"
      strokeOpacity="0.15"
      strokeDasharray="4 10"
    />
    <path
      opacity="0.05"
      d="M80.3081 125.973C105.161 125.973 125.308 105.825 125.308 80.9727C125.308 56.1198 105.161 35.9727 80.3081 35.9727C55.4553 35.9727 35.3081 56.1198 35.3081 80.9727C35.3081 105.825 55.4553 125.973 80.3081 125.973Z"
      fill="url(#paint0_linear_374_6216)"
    />
    <path
      d="M91.7424 79.6301L94.0077 84.9967L80.0068 94.7126L66.006 84.9967M91.7424 79.6301L94.0077 73.1126L80.0068 65.7119L66.006 73.1126M91.7424 79.6301H68.2712M66.006 73.1126L68.2712 79.6301M66.006 73.1126L64 67.5721L70.6456 62.5M68.2712 79.6301L66.006 84.9967M66.006 84.9967L64 91.7015L72.0239 98.5M80.0068 79.3758V94.4584M93.994 84.9967L96 91.7015L87.9761 98.5M93.994 73.1126L96 67.5721L89.3544 62.5M73.1974 68.897L80.0068 72.7647L86.8026 68.897"
      stroke="white"
      strokeWidth="3.8"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_374_6216"
        x1="125.469"
        y1="125.648"
        x2="35.3449"
        y2="36.3153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
