import React from 'react'

const Line = () => (
  <svg width="2" height="92" viewBox="0 0 2 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.99976 91.9282L0.999756 0.928223"
      stroke="white"
      strokeOpacity="0.15"
      strokeDasharray="4 10"
    />
  </svg>
)

export default Line
