import React from 'react'
// Utils.
import createDownloadZip from './createDownloadZip'

// Styles & images.
import 'src/components/pages/brand/DownloadLogoSet/scss/Link.scss'

function Link({ copy, name, items }) {
  const handleZipDownload = () => {
    createDownloadZip({ files: items, name })
  }

  return (
    <button className="x__brand-page__download-link" onClick={handleZipDownload}>
      <img src="/brand/img/file-icon.png" />
      <span>{copy}</span>
      <img src="/brand/img/download-icon.svg" />
    </button>
  )
}

export default Link
